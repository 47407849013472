function onePsiToOneBar(compressibility:number):number {
    const result = compressibility * 14.5;

    return result;
}

function onePsiToOnePa(compressibility:number):number {
    const result = compressibility * 1.45E-04;

    return result;
}

const compressibility = {
    onePsiToOneBar: onePsiToOneBar,
    onePsiToOnePa: onePsiToOnePa
}

export default compressibility;