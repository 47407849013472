import Utils from "../Utils";

function fahrenheitToCelsius(temperature:number):number {
	const result = (temperature - 32) * 5/9;
	return Utils.roundTo(result, 1);
}

const temperature = {
	fahrenheitToCelsius: fahrenheitToCelsius
}

export default temperature;