import Utils from "../Utils";

function oneKToOneF(thermalExpansion:number):number {
	const result = thermalExpansion * 0.556;

	return Utils.roundTo(result, 6);
}

function oneKToOneC(thermalExpansion:number):number {
	const result = thermalExpansion * 1;

	return result;
}

const thermalExpansion = {
	oneKToOneF: oneKToOneF,
	oneKToOneC: oneKToOneC
}

export default thermalExpansion;
