export enum ErrorType {
	/** The input is out of range, but the calculation still works. */
	WARNING = 'WARNING',
	/** The input is out of range and the calculation will not work. */
	ERROR = 'ERROR'
}

export interface validationResult {
	/** If true, then the inputed density is in the range of the calculations. If not, then the fields below should have values. */
	okay: boolean,
	/** Tells if the error is in fact an error or if it is a warning. */
	errorType?: ErrorType,
	/** Error message. */
	message?: string,
}

/**
 * Validate if the input is in the range of the calculation.
 * @param {number} density - The density in gcm3.
 * @returns {validationResult}
 */
const validateDensity = (density:number):validationResult => {
	if(density < 1 || density > 2.3) {
		return { 
			okay: false,
			errorType: ErrorType.ERROR,
			message: 'Out of range. The minimum value is 1 gcm³ and the maximum is 2.3 gcm³.'
		};
	}

	if(density < 1.008) {
		return {
			okay: false,
			errorType: ErrorType.WARNING,
			message: 'Warning: Data extrapolated. Keep in mind that the minimum value is 1 gcm³.'
		};
	}

	if(density > 2.285) {
		return {
			okay: false,
			errorType: ErrorType.WARNING,
			message: 'Warning: Data extrapolated. Keep in mind that the maximum value is 2.3 gcm³.'
		}
	}

	return {okay: true}
}

/**
 * Validate if the input is in the range of the calculation.
 * @param {number} temperature - The temperature in ºC.
 * @returns {validationResult}
 */
const validateTemperature = (temperature:number):validationResult => {
	if(temperature < 0 || temperature > 250) {
		return {
			okay: false,
			errorType: ErrorType.ERROR,
			message: 'Out of range. The minimum value is 0 ºC and the maximum is 250 ºC.'
		};
	}

	if(temperature > 175) {
		return {
			okay: false,
			errorType: ErrorType.WARNING,
			message: 'Warning: Data extrapolated. Keep in mind that the maximum value is 250 ºC.'
		};
	}

	return {okay:true};
}

/**
 * Validate if the input is in the range of the calculation.
 * @param {number} pressure - The pressure in psi.
 * @returns {validationResult}
 */
const validatePressure = (pressure:number):validationResult => {
	if(pressure < 0 || pressure > 30_000) {
		return {
			okay: false,
			errorType: ErrorType.ERROR,
			message: 'Out of range. The minimum value is 0 psi and the maximum is 30000 psi.'
		};
	}

	if(pressure > 20_000) {
		return {
			okay: false,
			errorType: ErrorType.WARNING,
			message: 'Warning: Data extrapolated. Keep in mind that the maximum value is 30000 psi.'
		};
	}

	return {okay: true};
}

const Validations = {
	validateDensity: validateDensity,
	validateTemperature: validateTemperature,
	validatePressure: validatePressure
}

export default Validations;
