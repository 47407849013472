
import density from "./density";
import temperature from "./temperature";
import thermalExpansion from "./thermalExpansion";
import pressure from "./pressure";
import compressibility from "./compressibility";

const Conversions = {
	temperature: temperature,
	thermalExpansion: thermalExpansion,
	density: density,
	pressure: pressure,
	compressibility: compressibility
}

export default Conversions;