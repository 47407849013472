import Utils from "../Utils";

function barToPsi(pressure:number):number {
	const result = pressure * 14.5;

	return Utils.roundTo(result, 0);
}

function mpaToPsi(pressure:number):number {
	const result = pressure * 145.03;

	return Utils.roundTo(result, 0);
}

function kpaToPsi(pressure:number):number {
	const result = pressure * 0.145;

	return Utils.roundTo(result, 0);
}

const pressure = {
	barToPsi: barToPsi,
	mpaToPsi: mpaToPsi,
	kpaToPsi: kpaToPsi
}

export default pressure;