import Utils from "../Utils";

function lbgalToGcm3(density:number):number {
	const result = density * 0.119832;

	return Utils.roundTo(result, 4);
}

function pptfToGcm3(density:number):number {
	const result = density * 0.002304731614004;

	return Utils.roundTo(result, 4);
}

function gcm3ToLbgal(density:number):number {
	const result = density / (1/8.345);

	return Utils.roundTo(result, 4);
}

function gcm3ToPptf(density:number):number {
	const result = density / (1/433.89);

	return Utils.roundTo(result, 4);
}

function gcm3ToSg(density:number):number {
	const result = density / 0.999;

	return result;
}

function sgToGcm3(density:number) {
	const result = density * 0.999;

	return result;
}

const density = {
	lbgalToGcm3: lbgalToGcm3,
	pptfToGcm3: pptfToGcm3,
	gcm3ToLbgal: gcm3ToLbgal,
	gcm3ToPptf: gcm3ToPptf,
	gcm3ToSg: gcm3ToSg,
	sgToGcm3: sgToGcm3
}

export default density;
