import Macros from "../Macros";

function roundTo(x:number, decimals:number) {
	return Number(x.toFixed(decimals));
}

interface row {
    startPosition:number,
    endPosition:number,
    minRefDensity:number,
    maxRefDensity:number,
    minDensity?:number,
    maxDensity?:number
}

const data:Array<row> = [
    { startPosition: -0.006259780907668, endPosition: 0, minRefDensity: 1.008, maxRefDensity: 1.101 },
    { startPosition: 0, endPosition: 0.151, minRefDensity: 1.101, maxRefDensity: 1.201 },
    { startPosition: 0.151, endPosition: 0.229, minRefDensity: 1.201, maxRefDensity: 1.301 },
    { startPosition: 0.229, endPosition: 0.307, minRefDensity: 1.301, maxRefDensity: 1.400 },
    { startPosition: 0.307, endPosition: 0.441, minRefDensity: 1.400, maxRefDensity: 1.572 },
    { startPosition: 0.441, endPosition: 0.682, minRefDensity: 1.572, maxRefDensity: 1.880 },
    { startPosition: 0.682, endPosition: 0.854, minRefDensity: 1.880, maxRefDensity: 2.100 },
    { startPosition: 0.854, endPosition: 1, minRefDensity: 2.100, maxRefDensity: 2.286 },
    { startPosition: 1, endPosition: 1.011, minRefDensity: 2.286, maxRefDensity: 2.300 }
]


function densityPosition(densityInGcm3:number):number {
    return (densityInGcm3 - 1.008) / (2.286 - 1.008);
}

/**
 * Calculates the density value when the input density is 2.3 (the maximum value for density).
 */
function calcMaxRefDensityForEdgeValue(temperatureInCelsius:number, pressureInPsi:number):number {
    const J5:number = 2.1;
    const K5:number = 2.286;
    const L5:number = 2.3;
    let maxRefDensity = 0;

    const minRefDensity = Macros.densityFitted(K5, temperatureInCelsius, pressureInPsi);
    const leftMaxRefDensity = Macros.densityFitted(J5, temperatureInCelsius, pressureInPsi);

    maxRefDensity = (leftMaxRefDensity * -1) * (L5 - K5) / (K5 - J5) +  minRefDensity * (L5 - J5) / (K5 - J5);

    return maxRefDensity;
}

function calcDensity(densityInGcm3:number, temperatureInCelsius:number, pressureInPsi:number) {
    let result:number = 0;
    const position = densityPosition(densityInGcm3);

    const myRow = data.find(element => position >= element.startPosition && position <= element.endPosition );
    if(!myRow) throw "Invalid position: " + position;

    const minRefDensity = myRow.minRefDensity;
    const maxRefDensity = myRow.maxRefDensity;
    const minDensity = Macros.densityFitted(minRefDensity, temperatureInCelsius, pressureInPsi);
    let maxDensity:number = 0;
    if(densityInGcm3 === 2.3)
        maxDensity = calcMaxRefDensityForEdgeValue(temperatureInCelsius, pressureInPsi);
    else
        maxDensity = Macros.densityFitted(maxRefDensity, temperatureInCelsius, pressureInPsi);

    result = minDensity * (maxRefDensity - densityInGcm3) / (maxRefDensity - minRefDensity) + maxDensity * (densityInGcm3 - minRefDensity) / (maxRefDensity - minRefDensity);

    return roundTo(result, 3);
}

function calcCompressibility(densityInGcm3:number, temperatureInCelsius:number, pressureInPsi:number) {
    let result:number = 0;
    const position = densityPosition(densityInGcm3);

    const myRow = data.find(element => position >= element.startPosition && position <= element.endPosition );
    if(!myRow) throw "Invalid position: " + position;

    const minRefDensity = myRow.minRefDensity;
    const maxRefDensity = myRow.maxRefDensity;
    const minCompressibility = Macros.compressFitted(minRefDensity, temperatureInCelsius, pressureInPsi);
    const maxCompressibility = Macros.compressFitted(maxRefDensity, temperatureInCelsius, pressureInPsi);

    result = minCompressibility * (maxRefDensity - densityInGcm3) / (maxRefDensity - minRefDensity) + maxCompressibility * (densityInGcm3 - minRefDensity) / (maxRefDensity - minRefDensity);

    return roundTo(result, 8);
}

function calcThermalExpansion(densityInGcm3:number, temperatureInCelsius:number, pressureInPsi:number) {
    let result:number = 0;
    const position = densityPosition(densityInGcm3);

    const myRow = data.find(element => position >= element.startPosition && position <= element.endPosition );
    if(!myRow) throw "Invalid position: " + position;

    const minRefDensity = myRow.minRefDensity;
    const maxRefDensity = myRow.maxRefDensity;
    const minThermalExpansion = Macros.thermExpFitted(minRefDensity, temperatureInCelsius, pressureInPsi);
    const maxThermalExpansion = Macros.thermExpFitted(maxRefDensity, temperatureInCelsius, pressureInPsi);

    result = minThermalExpansion * (maxRefDensity - densityInGcm3) / (maxRefDensity - minRefDensity) + maxThermalExpansion * (densityInGcm3 - minRefDensity) / (maxRefDensity - minRefDensity);

    return roundTo(result, 6);
}

const Pvt = {
    calcDensity: calcDensity,
    calcCompressibility: calcCompressibility,
    calcThermalExpansion: calcThermalExpansion
};

export default Pvt;