import Utils from "../Utils";

function round(x:number) {
	return Utils.roundTo(x, 3);
}

function A(density:number):number{
	switch(density) {
		case 1.008:
			return 1.013411366;
		case 1.101:
			return 1.106654069;
		case 1.201:
			return 1.212174024;
		case 1.301:
			return 1.298327379;
		case 1.4:
			return 1.399840454;
		case 1.572:
			return 1.581388424;
		case 1.88:
			return 1.901954542;
		case 2.1:
			return 2.130630528;
		case 2.286:
			return 2.299010265;
		default:
			throw `Error in MACRO A: Invalid input for density. Input: ${density}`;
	}
}

function B(density:number):number{
	switch(density) {
		case 1.008:
			return 0.00000304013;
		case 1.101:
			return 0.0000026118;
		case 1.201:
			return 0.00000223695;
		case 1.301:
			return 0.00000210627;
		case 1.4:
			return 0.0000019894;
		case 1.572:
			return 0.00000192612;
		case 1.88:
			return 0.00000253302;
		case 2.1:
			return 0.00000229427;
		case 2.286:
			return 0.00000268176;
		default:
			throw `Error in MACRO B: Invalid input for density. Input: ${density}`;
	}
}

function C(density:number):number{
	switch(density) {
		case 1.008:
			return -0.00029071;
		case 1.101:
			return -0.00041328;
		case 1.201:
			return -0.0005219;
		case 1.301:
			return -0.00059158;
		case 1.4:
			return -0.00061063;
		case 1.572:
			return -0.00064309;
		case 1.88:
			return -0.00092157;
		case 2.1:
			return -0.00098852;
		case 2.286:
			return -0.00109129;
		default:
			throw `Error in MACRO C: Invalid input for density. Input: ${density}`;
	}
}

function D(density:number):number{
	switch(density) {
		case 1.008:
			return -0.000000000021178;
		case 1.101:
			return -0.000000000018591;
		case 1.201:
			return -0.000000000014249;
		case 1.301:
			return -0.000000000011915;
		case 1.4:
			return -8.3322E-12;
		case 1.572:
			return -5.9471E-12;
		case 1.88:
			return -0.000000000018557;
		case 2.1:
			return 3.6427E-13;
		case 2.286:
			return -0.000000000016889;
		default:
			throw `Error in MACRO D: Invalid input for density. Input: ${density}`;
	}
}

function E(density:number):number{
	switch(density) {
		case 1.008:
			return -0.0000022392;
		case 1.101:
			return -0.0000011367;
		case 1.201:
			return -0.00000070042;
		case 1.301:
			return -0.00000027982;
		case 1.4:
			return -0.00000018126;
		case 1.572:
			return -0.000000019459;
		case 1.88:
			return 0.000000214124;
		case 2.1:
			return 0.000000173774;
		case 2.286:
			return 0.0000000662684;
		default:
			throw `Error in MACRO E: Invalid input for density. Input: ${density}`;
	}
}

function F(density:number):number{
	switch(density) {
		case 1.008:
			return 0.00000000398082;
		case 1.101:
			return 0.00000000417786;
		case 1.201:
			return 0.00000000427145;
		case 1.301:
			return 0.00000000363384;
		case 1.4:
			return 0.00000000313603;
		case 1.572:
			return 0.00000000264436;
		case 1.88:
			return 0.00000000459565;
		case 2.1:
			return 0.00000000587805;
		case 2.286:
			return 0.00000000770052;
		default:
			throw `Error in MACRO F: Invalid input for density. Input: ${density}`;
	}
}

function notRoundedDensityFitted(density:number, temperature:number, pressure:number):number {
	const result:number = A(density) + B(density) * pressure + C(density) * temperature + D(density) * Math.pow(pressure, 2) + E(density) * Math.pow(temperature, 2) + F(density) * temperature * pressure;
	return result;
}

function densityFitted(density:number, temperature:number, pressure:number):number {
	const result = A(density) + B(density) * pressure + C(density) * temperature + D(density) * Math.pow(pressure, 2) + E(density) * Math.pow(temperature, 2) + F(density) * temperature * pressure;
	return round(result);
}

function compressFitted(density:number, temperature:number, pressure:number):number {
	const result:number = (B(density) + 2 * D(density) * pressure + F(density) * temperature) / notRoundedDensityFitted(density, temperature, pressure);

	return Utils.roundTo(result, 8);
}

function thermExpFitted(density:number, temperature:number, pressure:number):number {
	const result:number = -(C(density) + 2 * E(density) * temperature + F(density) * pressure) / notRoundedDensityFitted(density, temperature, pressure);

	return result;
}

const Macros = {
	A: A,
	B: B,
	C: C,
	D: D,
	E: E,
	F: F,
	densityFitted: densityFitted,
	compressFitted: compressFitted,
	thermExpFitted: thermExpFitted
}

export default Macros;